
/*
* Mobile phone and portrait styling.
*/
/* @media (orientation: portrait)
{
    :root
    {
        --size-pool-ball: 22vw;
    }
}

@media (orientation: landscape)
{
    :root
    {
        --size-pool-ball: 12vh;
    }
} */

.poolBall
{
    border: 0px solid transparent;
    border-radius: 50%;

    overflow: hidden;
}

.poolBallStripe
{
    width: 100%;
}

.poolBallNumber
{
    /* Centre the text */
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    margin: auto;

    text-align: center;

    background-color: white;
    color: black;

    font-weight: bold;

    font-family: Verdana;
}