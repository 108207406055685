.gridPoolBall
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* .conPoolBall
{
    display: inline-block;
}

.gridPoolBallInner
{
    margin: auto;
} */